import { HttpService, Response } from '@common-services/http';
import { FundingEntityUpdatePayload } from '@type/FundingEntity';

const FUNDING_ENTITY = '/api/v1/fundingEntities/{fundingEntityId}';
const FUNDING_ENTITY_USERS = '/api/v1/users';

const httpFundingEntity = HttpService.root(FUNDING_ENTITY);
const httpFundingEntityUsers = HttpService.root(FUNDING_ENTITY_USERS);

const getFundingEntity = async (id: number): Promise<Response> => {
  return await httpFundingEntity.get({
    urlReplacements: { '{fundingEntityId}': id.toString() },
  });
};

const getFundingEntityUsers = async (): Promise<Response> => {
  return await httpFundingEntityUsers.get({});
};

const updateFundingEntity = async (id: number, fundingEntity: FundingEntityUpdatePayload): Promise<Response> => {
  return await httpFundingEntity.put({
    urlReplacements: { '{fundingEntityId}': id.toString() },
    entity: fundingEntity,
  });
};

export const FundingEntityService = {
  getFundingEntity,
  getFundingEntityUsers,
  updateFundingEntity,
};
