import { Cohort } from '@type/Cohort';
import { objectSlice } from '@common-features/slicer';
import { requestGetCohortList, requestGetCohort } from './cohortsThunks';

const getCohortList = objectSlice<Cohort[]>({
  name: 'getCohortList',
  thunk: requestGetCohortList,
  keyFn: () => 'list',
});

const getCohort = objectSlice<Cohort>({
  name: 'getCohort',
  thunk: requestGetCohort,
  keyFn: ({ arg }) => arg, // get by cohort id
});

const reducers = {
  getCohortList: getCohortList.reducer,
  getCohort: getCohort.reducer,
};

export default reducers;
