import { Response } from '@common-services/http';
import { UserService } from '@common-services/user';
import { UpdateCurrentUserPayload, User } from '@common-type/User';
import strings from '@common-src/strings';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const requestGetCurrentUser = createAsyncThunk('getCurrentUser', async (_, { rejectWithValue }) => {
  const response: Response = await UserService.getCurrentUser();
  if (response.requestSucceeded) {
    return response.data?.user as User;
  } else {
    return rejectWithValue(response.error || strings.GENERIC_ERROR);
  }
});

export const requestUpdateCurrentUser = createAsyncThunk(
  'updateCurrentUser',
  async (toUpdate: UpdateCurrentUserPayload, { rejectWithValue }) => {
    const response: Response = await UserService.updateCurrentUser(toUpdate);
    if (response.requestSucceeded) {
      return true;
    } else {
      return rejectWithValue(response.error || strings.GENERIC_ERROR);
    }
  }
);
