import { HttpService, Response } from '@common-services/http';

const TRANSACTIONS = '/api/v1/transactions';

const httpTransactions = HttpService.root(TRANSACTIONS);

const getTransactions = async (cohortId?: string): Promise<Response> =>
  await httpTransactions.get({
    params: cohortId ? { cohortId } : {},
  });

export const TransactionsService = {
  getTransactions,
};
