import { createAsyncThunk } from '@reduxjs/toolkit';
import { Response } from '@common-services/http';
import { FundingEntityService } from '@services/fundingEntity';
import strings from '@common-src/strings';
import { FundingEntity, FundingEntityUpdatePayload } from '@type/FundingEntity';

type UpdateFundingEntityPayload = {
  id: number;
  data: FundingEntityUpdatePayload;
};

export const requestGetFundingEntity = createAsyncThunk('getFundingEntity', async (id: number, { rejectWithValue }) => {
  const response: Response = await FundingEntityService.getFundingEntity(id);
  if (response.requestSucceeded) {
    return response.data.entity as FundingEntity;
  }

  return rejectWithValue(response.error || strings.GENERIC_ERROR);
});

export const requestUpdateFundingEntity = createAsyncThunk(
  'updateFundingEntity',
  async (payload: UpdateFundingEntityPayload, { rejectWithValue }) => {
    const response: Response = await FundingEntityService.updateFundingEntity(payload.id, payload.data);
    if (response.requestSucceeded) {
      return response.data.entity as FundingEntity;
    }

    return rejectWithValue(response.error || strings.GENERIC_ERROR);
  }
);
