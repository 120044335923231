import { HttpService, Response } from '@common-services/http';
import { UpdateCurrentUserPayload } from '@common-type/User';

const CURRENT_USER_ENDPOINT = '/api/v1/users/me';

const getCurrentUser = async (): Promise<Response> => await HttpService.root(CURRENT_USER_ENDPOINT).get({});

const updateCurrentUser = async (toUpdate: UpdateCurrentUserPayload): Promise<Response> =>
  await HttpService.root(CURRENT_USER_ENDPOINT).put({ entity: toUpdate });

export const UserService = {
  getCurrentUser,
  updateCurrentUser,
};
