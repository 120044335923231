import { createAsyncThunk } from '@reduxjs/toolkit';
import { Response } from '@common-services/http';
import { Participant } from '@type/Participant';
import { ParticipantsService } from '@services/participant';
import strings from '@common-src/strings';

export const requestGetParticipantList = createAsyncThunk(
  'getParticipantList',
  async (cohortId: number | string, { rejectWithValue }) => {
    const response: Response = await ParticipantsService.getParticipants(cohortId.toString());
    if (response.requestSucceeded) {
      return response.data.participants as Participant[];
    }

    return rejectWithValue(response.error || strings.GENERIC_ERROR);
  }
);

export const requestGetParticipant = createAsyncThunk('getParticipant', async (id: number, { rejectWithValue }) => {
  const response: Response = await ParticipantsService.getParticipant(id);
  if (response.requestSucceeded) {
    return response.data.participant as Participant;
  }

  return rejectWithValue(response.error || strings.GENERIC_ERROR);
});
