import { objectSlice } from '@common-features/slicer';
import { requestGetFundingEntityUsers } from './fundingEntityUsersThunks';
import { FundingEntityUser } from '@type/FundingEntityUser';

const getFundingEntityUsers = objectSlice<FundingEntityUser[]>({
  name: 'getFundingEntityUsers',
  thunk: requestGetFundingEntityUsers,
  keyFn: () => 'users', // constant key
}).reducer;

const reducers = {
  getFundingEntityUsers,
};

export default reducers;
