import React, { Component, ReactNode } from 'react';
import ErrorPage from '@common-components/ErrorPage';

interface Props {
  children: ReactNode;
}

interface State {
  error?: Error;
}

export default class ErrorBoundary extends Component<Props, State> {
  // when an error occurred we want to update the statee
  public static getDerivedStateFromError(_e: Error): State {
    // Update state so the next render will show the error UI.

    return { error: _e };
  }

  public state: State = {};

  public render(): ReactNode {
    if (this.state.error) {
      return <ErrorPage error={this.state.error?.message} />;
    }

    return this.props.children;
  }
}
