import { useEffect } from 'react';
import { requestGetCurrentUser } from '@common-features/currentUser/currentUserThunks';
import { useAppDispatch } from '@common-redux/store';

// simple hook used to dispatch current user request
export const useCurrentUser = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // trigger current user request
    dispatch(requestGetCurrentUser());
  }, [dispatch]);
};
