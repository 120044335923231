// Get web app environment
const useEnvironment = () => {
  const isProduction = !!window.location.origin.match(/^https:\/\/(www.)?data.terraformation.com/);
  const isStaging =
    !isProduction && !!window.location.origin.match(/^https:\/\/(www.)?staging.data.terraformation.com/);
  const isVercel = !isProduction && !isStaging && window.location.origin.endsWith('.vercel.app');
  const isDev = !(isProduction || isStaging);

  return {
    isProduction,
    isStaging,
    isVercel,
    isDev,
  };
};

export default useEnvironment;
