import { createAsyncThunk } from '@reduxjs/toolkit';
import { Response } from '@common-services/http';
import strings from '@common-src/strings';
import { Country } from '@common-type/Country';
import { CountriesService } from '@common-services/countries';

export const requestGetCountries = createAsyncThunk('getCountries', async (_, { rejectWithValue }) => {
  const response: Response = await CountriesService.listCountries();
  if (response.requestSucceeded) {
    return response.data.countries as Country[];
  }

  return rejectWithValue(response.error || strings.GENERIC_ERROR);
});
