import React from 'react';
import { Box } from '@mui/material';
import ErrorMessage from '@common-components/ErrorMessage';

export type ErrorPageProps = {
  error?: string;
};

const ErrorPage = ({ error }: ErrorPageProps): JSX.Element => {
  return (
    <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
      <ErrorMessage error={error} />
    </Box>
  );
};

export default ErrorPage;
