import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import BusyPageSpinner from '@common-components/BusyPageSpinner';

const useStyles = makeStyles((styleTheme: Theme) => ({
  container: {
    background: `linear-gradient(180deg, ${styleTheme.palette.TwClrBaseGray025} 0%, ${styleTheme.palette.TwClrBgBrandTertiary} 100%)`,
    display: 'flex',
    width: '100vw',
    height: '100vh',
  },
}));

/**
 * This component handles login error redirect from keycloak
 * and redirects user to logout. Avoids infinite loops otherwise.
 */
const Login = (): JSX.Element => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);

  if (urlParams.has('error')) {
    return (
      <Box className={classes.container} flexDirection='column' justifyContent='center' alignItems='center'>
        <h2>There was an error signin in.</h2>
        <NavLink to='/'>Go back</NavLink>
      </Box>
    );
  } else {
    navigate('/');
    return <BusyPageSpinner />;
  }
};

export default Login;
