import { objectSlice } from '@common-features/slicer';
import {
  requestGetTransactionList,
  requestGetTransactionsByCohortList,
} from '@features/transactions/transactionsThunks';
import { Transaction } from '@type/Transaction';

const getTransactionList = objectSlice<Transaction[]>({
  name: 'getTransactionList',
  thunk: requestGetTransactionList,
  keyFn: () => 'list',
});

const getTransactionsByCohortList = objectSlice<Transaction[]>({
  name: 'getTransactionList',
  thunk: requestGetTransactionsByCohortList,
  keyFn: ({ arg }) => arg,
});

const reducers = {
  getTransactionList: getTransactionList.reducer,
  getTransactionsByCohortList: getTransactionsByCohortList.reducer,
};

export default reducers;
