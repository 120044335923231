import { HttpService, Response } from '@common-services/http';

const PARTICIPANTS = '/api/v1/participants';
const PARTICIPANT = '/api/v1/participants/{participantId}';

const httpParticipants = HttpService.root(PARTICIPANTS);
const httpParticipant = HttpService.root(PARTICIPANT);

const getParticipants = async (cohortId: string): Promise<Response> =>
  await httpParticipants.get({
    params: { cohortId },
  });

const getParticipant = async (id: number): Promise<Response> =>
  await httpParticipant.get({
    urlReplacements: { '{participantId}': id.toString() },
  });

export const ParticipantsService = {
  getParticipants,
  getParticipant,
};
