import { createAsyncThunk } from '@reduxjs/toolkit';
import { Response } from '@common-services/http';
import strings from '@common-src/strings';
import { FilesService } from '@services/participantFile';
import { ParticipantFile } from '@type/ParticipantFile';

export const requestGetParticipantFilesList = createAsyncThunk(
  'getParticipantFilesList',
  async (participantId: number | string, { rejectWithValue }) => {
    const response: Response = await FilesService.getFiles(participantId.toString());
    if (response.requestSucceeded) {
      return response.data.files as ParticipantFile[];
    }

    return rejectWithValue(response.error || strings.GENERIC_ERROR);
  }
);
