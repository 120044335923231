import { objectSlice } from '@common-features/slicer';
import { requestGetUserPreferences, requestUpdateUserPreferences } from './userPreferencesThunks';
import { UserPreferences } from '@common-type/UserPreferences';

const getUserPreferencesSlice = objectSlice<UserPreferences>({
  name: 'getUserPreferences',
  thunk: requestGetUserPreferences,
  keyFn: () => 'getPreferences', // use a hardcoded key
});

const updateUserPreferencesSlice = objectSlice<UserPreferences>({
  name: 'updateUserPreferences',
  thunk: requestUpdateUserPreferences,
  keyFn: () => 'updatePreferences', // use a hardcoded key
});

const reducers = {
  getUserPreferences: getUserPreferencesSlice.reducer,
  updateUserPreferences: updateUserPreferencesSlice.reducer,
};

export default reducers;
