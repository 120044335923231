import React, { useEffect, useState } from 'react';
import { UserContext } from './contexts';
import { UserData } from './data';
import { useAppSelector } from '@common-redux/store';
import { useCurrentUser } from '@common-hooks/useCurrentUser';
import { selectGetCurrentUser } from '@common-features/currentUser/currentUserSelector';

export type UserProviderProps = {
  children?: React.ReactNode;
};

export default function UserProvider({ children }: UserProviderProps): JSX.Element {
  const [userData, setUserData] = useState<UserData>({});

  // request user data
  useCurrentUser();

  // listen for data
  const currentUser = useAppSelector(selectGetCurrentUser);

  useEffect(() => {
    setUserData({
      user: currentUser?.data,
      error: currentUser?.error ?? '',
    });
  }, [currentUser]);

  return <UserContext.Provider value={userData}>{children}</UserContext.Provider>;
}
