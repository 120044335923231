import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthService } from '@common-services/auth';

export const LOGOUT_ACTION_PREFIX = 'logout';

export const requestLogout = createAsyncThunk(LOGOUT_ACTION_PREFIX, async () => {
  await AuthService.logout();

  return true;
});
