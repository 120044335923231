import { createAsyncThunk } from '@reduxjs/toolkit';
import { Response } from '@common-services/http';
import strings from '@common-src/strings';
import { TransactionsService } from '@services/transactions';
import { Transaction } from '@type/Transaction';

export const requestGetTransactionList = createAsyncThunk('getTransactionList', async (_, { rejectWithValue }) => {
  const response: Response = await TransactionsService.getTransactions();
  if (response.requestSucceeded) {
    return response.data.transactions as Transaction[];
  }

  return rejectWithValue(response.error || strings.GENERIC_ERROR);
});

export const requestGetTransactionsByCohortList = createAsyncThunk(
  'getTransactionsByCohortList',
  async (cohortId: number | string, { rejectWithValue }) => {
    const response: Response = await TransactionsService.getTransactions(cohortId.toString());
    if (response.requestSucceeded) {
      return response.data.transactions as Transaction[];
    }

    return rejectWithValue(response.error || strings.GENERIC_ERROR);
  }
);
