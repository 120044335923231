import { HttpService, Response } from '@common-services/http';
import { UserPreferences } from '@common-type/UserPreferences';

const USER_PREFERENCES_ENDPOINT = '/api/v1/users/me/preferences';

const getUserPreferences = async (): Promise<Response> => await HttpService.root(USER_PREFERENCES_ENDPOINT).get({});

const setUserPreferences = async (newPrefs: UserPreferences): Promise<Response> => {
  return HttpService.root(USER_PREFERENCES_ENDPOINT).put({ entity: { value: newPrefs } });
};

export const UserPreferencesService = {
  getUserPreferences,
  setUserPreferences,
};
