import LocalizedStrings, { GlobalStrings } from 'react-localization';

const strings = {
  ABOUT: 'About',
  ACCELERATOR_FUNDER_PORTAL: 'Accelerator Funder Portal',
  ACCELERATOR_FUNDER_ADMIN_PORTAL: 'Funder Portal Admin',
  ACCELERATOR_STATUS: 'Accelerator Status',
  ACCOUNT: 'Account',
  ADD: 'Add',
  ADD_COHORT: 'Add Cohort',
  ADD_FUNDING_ENTITY: 'Add Funding Entity',
  ADD_PARTICIPANT: 'Add Participant',
  ADD_PERSON: 'Add Person',
  ADD_REMOVE_PERSONS: 'Need to add or remove users?',
  ADD_TRANSACTION: 'Add Transaction',
  ADD_TRANSACTION_COHORT_INFO: 'Only cohorts this funding entity is assigned to will appear.',
  ADD_TRANSACTION_FUNDING_ENTITY_INFO: 'Only funding entities assigned to this cohort will appear.',
  ADD_USER: 'Add User',
  ADMIN: 'Admin',
  ADMIN_EMAIL_EXISTS: 'This email is already used for an admin.',
  ADMINS: 'Admins',
  AFFORDABLE_AND_CLEAN_ENERGY: 'Affordable and Clean Energy',
  AMOUNT_USD: 'Amount (USD)',
  AMOUNT_USD_REQUIRED: 'Amount (USD) *',
  ASSOCIATED_COHORTS: 'Associated Cohorts',
  APPLY: 'Apply',
  BUSINESS_INFO: 'Business Info',
  CANCEL: 'Cancel',
  CAPACITY: 'Capacity',
  CAPTION: 'Caption',
  CARBON_REMOVAL: 'Carbon Removal',
  CARBON_SEQUESTERED_TONNE: 'Carbon Sequestered (tonne)',
  CARBON_SEQUESTRATION_TARGET_TONNE: 'Carbon Sequestration Target (tonne)',
  CARD_BODY_COHORTS: 'Add accelerator cohorts and view their associated participants and funding entities.',
  CARD_BODY_PARTICIPANTS: 'Add accelerator participants and manage their information.',
  CARD_BODY_FUNDING_ENTITIES: 'Add funding entities and invite associated users.',
  CHOOSE_FILE: 'Choose File...',
  CLEAN_WATER_AND_SANITATION: 'Clean Water and Sanitation',
  CLIMATE_ACTION: 'Climate Action',
  COHORT: 'Cohort',
  COHORT_REQUIRED: 'Cohort *',
  COHORT_DETAILS: 'Cohort Details',
  COHORT_DETAILS_DESCRIPTION: "Enter this cohort's information below.",
  COHORT_STATUS: 'Cohort Status',
  COHORTS: 'Cohorts',
  CONTACT_US: 'Contact Us',
  COULD_NOT_LOAD_COHORT: 'Could not load Cohort',
  COUNTRIES: 'Countries',
  COUNTRY: 'Country',
  COUNTRY_REQUIRED: 'Country *',
  COVER_PHOTO: 'Cover Photo',
  DATE: 'Date',
  DATE_ADDED: 'Date Added',
  DATE_RECEIVING: 'Date Receiving',
  DATE_REQUIRED: 'Date *',
  DECENT_WORK_AND_ECONOMIC_GROWTH: 'Decent Work and Economic Growth',
  DELETE: 'Delete',
  DELETE_FUNDING_ENTITY: 'Delete Funding Entity',
  DELETE_FUNDING_ENTITY_CONFIRMATION: 'You are about to delete the following funding entities: {0}',
  DELETE_TRANSACTIONS: 'Delete Transactions',
  DELETE_TRANSACTIONS_CONFIRMATION: 'You are about to delete {0} transaction(s)',
  DESCRIPTION: 'Description',
  DESCRIPTION_REQUIRED: 'Description *',
  EDIT: 'Edit',
  EDIT_ACCELERATOR_STATUS: 'Edit Accelerator Status',
  EDIT_ASSOCIATED_COHORTS: 'Edit Associated Cohorts',
  EDIT_ASSOCIATED_COHORTS_WARNING: "You're removing cohorts associated with funder's transactions.",
  EDIT_ASSOCIATED_COHORTS_WARNING_TRANSACTIONS:
    'By submitting the change, related funder transactions will be deleted.',
  EDIT_COHORT: 'Edit Cohort',
  EDIT_FUNDING_ENTITY: 'Edit Funding Entity',
  EDIT_PARTICIPANT: 'Edit Participant',
  EDIT_PERSON: 'Edit Person',
  EDIT_PROGRESS: 'Edit Progress',
  EMAIL: 'Email',
  EMAIL_REQUIRED: 'Email *',
  EMAIL_SUBJECT_CONTACT_US: 'Funder Portal - Contact Us',
  EMAIL_SUBJECT_USER_ACCESS: 'Funder Portal - Add/Remove User Request',
  FILTERS: 'Filters',
  FILTER: 'Filter',
  FINANCIAL_CORP: 'Financial Corp',
  FINANCIAL_OVERVIEW: 'Financial Overview',
  FINANCIAL_STATUS: 'Financial Status',
  FIRST_NAME: 'First Name',
  FIRST_NAME_REQUIRED: 'First Name *',
  FROM_TERRAFORMATION: 'From Terraformation',
  FUNDING_ENTITY: 'Funding Entity',
  FUNDING_ENTITY_REQUIRED: 'Funding Entity *',
  FUNDING_ENTITY_DETAILS: 'Funding Entity Details',
  FUNDING_ENTITY_DETAILS_DESCRIPTION: "Enter this funding entity's information below.",
  FUNDING_ENTITIES: 'Funding Entities',
  FUNDING_ENTITY_NAME: 'Funding Entity Name',
  FUNDING_RECEIVED_USD: 'Funding Received (USD)',
  FUNDING_TRANSACTIONS: 'Funding Transactions',
  FUNDS_FROM_TF_USD: 'Funds from TF (USD)',
  FUNDS_PROVIDED_USD: 'Funds Provided (USD)',
  FUNDS_RECEIVED_USD: 'Funds Received (USD)',
  FUNDS_SENT_USD: 'Funds Sent (USD)',
  FUNDS_TO_TF_USD: 'Funds to TF (USD)',
  GENDER_EQUALITY: 'Gender Equality',
  GENERIC_ERROR: 'Generic error',
  GO_TO_COHORTS: 'Go To Cohorts',
  GO_TO_PARTICIPANTS: 'Go To Participants',
  GO_TO_FUNDING_ENTITIES: 'Go To Funding Entities',
  GOOD_HEALTH_AND_WELL_BEING: 'Good Health and Well-Being',
  HECTARE_UNIT: 'ha',
  HOME: 'Home',
  ID: 'Id',
  IMPACT: 'Impact',
  IN_PROGRAM: 'In Program',
  INDUSTRY_INNOVATION_AND_INFRASTRUCTURE: 'Industry, Innovation and Infrastructure',
  INVALID_DATE: 'Invalid Date',
  INVALID_FIELD: 'Invalid field value',
  JOB_CREATION: 'Job Creation',
  JOBS: 'Jobs',
  JOBS_TOTAL_ERROR: 'Total jobs is less than number of women hired.',
  LAND_RESTORED: 'Land Restored',
  LAST_NAME: 'Last Name',
  LAST_NAME_REQUIRED: 'Last Name *',
  LAST_UPDATE: 'Last Update: {0}',
  LEARN_MORE: 'Learn more',
  LIFE_BELOW_WATER: 'Life Below Water',
  LIFE_ON_LAND: 'Life on Land',
  LOCATION: 'Location',
  LOGIN: 'Login',
  LOG_OUT: 'Log Out',
  MAKE_COVER_PHOTO: 'Make Cover Photo',
  MALE: 'Male',
  MEDIA: 'Media',
  MEDIA_ASSETS: 'Media Assets',
  MORE_ELLIPSIS: 'More...',
  MY_PROFILE: 'My Profile',
  NAME: 'Name',
  NAME_REQUIRED: 'Name *',
  NEXT: 'Next',
  NO: 'No',
  NO_COHORTS_TITLE: 'Funding Entity Not Assigned to Cohort/s',
  NO_COHORTS_MESSAGE: 'Assign this funding entity to cohorts by editing the associated cohorts section below.',
  NO_PARTICIPANT_COHORT_TITLE: 'Participant Not Assigned to Cohort',
  NO_PARTICIPANT_COHORT_MESSAGE: 'Assign this participant to a cohort by editing the accelerator status below.',
  NO_POVERTY: 'No Poverty',
  NONE: 'None',
  NUM_COUNTRIES: '{0} countries',
  NUM_PROJECTS: '{0} projects',
  NUMBER_OF_HA_RESTORED: 'Number of Ha Restored',
  NUMBER_OF_SPECIES: 'Number of Species',
  NUMBER_OF_SEED_BANKS: 'Number of Seed Banks',
  NUMBER_OF_NURSERIES: 'Number of Nurseries',
  NUMBER_OF_JOBS_CREATED: 'Number of Jobs Created',
  NUMBER_OF_WOMEN_HIRED: 'Number of Women Hired',
  NURSERY: 'Nursery',
  OK: 'Ok',
  OUT_OF_PROGRAM: 'Out of Program',
  OVERVIEW: 'Overview',
  PAGINATION_FOOTER_EMPTY: '0 of 0',
  PAGINATION_FOOTER: '{0} to {1} of {2}',
  PARTNERSHIPS_FOR_THE_GOALS: 'Partnerships for the Goals',
  PARTICIPANT_DETAILS: 'Participant Details',
  PARTIAL_ERRORS_TITLE: 'Partial Errors',
  PARTIAL_ERRORS: 'Some of the requests failed. You can retry again later.',
  PARTICIPANT_TYPE: 'Participant Type',
  PARTICIPANT_DETAILS_DESCRIPTION: 'Enter this participant’s information below.',
  PARTICIPANT: 'Participant',
  PARTICIPANTS: 'Participants',
  PAST_PARTICIPANTS: 'Past Participants',
  PEACE_JUSTICE_AND_STRONG_INSTITUTIONS: 'Peace, Justice and Strong Institutions',
  PENDING: 'Pending',
  PHASE: 'Phase',
  PHASE_REQUIRED: 'Phase *',
  PHASE_ONBOARDING: 'Onboarding',
  PHASE_PREPARATION: 'Preparation',
  PHASE_CARBON_PROJECT: 'Carbon Project',
  PHASE_0: 'Phase 0: Evaluation',
  PHASE_1: 'Phase 1: Onboarding',
  PHASE_2: 'Phase 2: Pilot',
  PHASE_3: 'Phase 3: Carbon Project',
  PHONE: 'Phone',
  PHOTO: 'Photo',
  PHOTOS: 'Photos',
  PHOTO_REQUIRED: 'Photo Required',
  PHOTO_REQUIRED_DESCRIPTION: 'You must choose a photo to save',
  PHOTO_SELECTED: 'Photo Selected',
  PLANT_SPECIES: 'Plant Species',
  PORTFOLIO: 'Portfolio',
  PREVIOUS: 'Previous',
  PRIMARY_CONTACT_EMAIL: 'Primary Contact Email',
  PRIMARY_CONTACT_PHONE: 'Primary Contact Phone',
  PROCEEDS_USD: 'Proceeds (USD)',
  PROGRESS: 'Progress',
  QUALITY_EDUCATION: 'Quality Education',
  REDUCED_INEQUALITIES: 'Reduced Inequalities',
  REFORESTATION: 'Reforestation',
  REGION: 'Region',
  REGION_REQUIRED: 'Region *',
  REMOVE: 'Remove',
  REMOVE_PERSON: 'Remove Person',
  REMOVE_PERSON_CONFIRMATION: 'You are about to remove {0} from Admins.',
  REMOVE_USER: 'Remove User',
  REMOVE_USER_CONFIRMATION: 'You are about to remove {0} from funding entity "{1}".',
  REPLACE_FILE: 'Replace File',
  REQUIRED_FIELD: 'Required field',
  RESET: 'Reset',
  RESPONSIBLE_CONSUMPTION_AND_PRODUCTION: 'Responsible Consumption and Production',
  ROLE: 'Role',
  ROLE_DESCRIPTION_ADMIN: 'An admin can manage cohorts, participants, funding entities and users.',
  ROLE_DESCRIPTION_SUPER_ADMIN: 'A super admin can do the above, as well as manage admins and super-admins.',
  ROLE_REQUIRED: 'Role *',
  ROWS_SELECTED: '{0} Rows selected',
  SAVE: 'Save',
  SEARCH: 'Search',
  SEED_BANK: 'Seed Bank',
  SEND: 'Send',
  SEND_QUARTERLY_UPDATE: 'Send Quarterly Update',
  SEND_QUARTERLY_UPDATE_CONFIRMATION:
    'You are about to send quarterly updates to all users emails associated with this cohort.',
  SELECT: 'Select...',
  SETTINGS: 'Settings',
  START_DATE: 'Start Date',
  START_DATE_REQUIRED: 'Start Date *',
  STATUS: 'Status',
  SUCCESS: 'Success',
  SUBMIT: 'Submit',
  SUPER_ADMIN: 'Super Admin',
  SUPPORTING_SDGS: 'Supporting SDGs',
  SUSTAINABLE_CITIES_AND_COMMUNITIES: 'Sustainable Cities and Communities',
  SUSTAINABLE_DEVELOPMENT_GOALS: 'Sustainable Development Goals',
  TARGET: 'Target',
  TARGET_PLANTING_AREA_HA: 'Target Planting Area (ha)',
  TONNE_UNIT: 'tn',
  TOTAL_CONTRIBUTION: 'Total Contribution',
  TOTAL_FUNDING: 'Total Funding',
  TO_FROM: 'To/From',
  TO_FROM_REQUIRED: 'To/From *',
  TO_TERRAFORMATION: 'To Terraformation',
  TRANSACTION_DETAILS: 'Transaction Details',
  TRANSACTION_HISTORY: 'Transaction History',
  TRANSACTIONS: 'Transactions',
  TYPE: 'Type',
  TYPE_OF_FORESTRY: 'Type of Forestry',
  UNSPECIFIED: '--',
  UPDATE: 'Update',
  UPLOAD: 'Upload',
  UPLOAD_PHOTO: 'Upload Photo',
  UPLOAD_PHOTO_DESCRIPTION: 'Browse or drag and drop a file (JPG, PNG).',
  UPLOAD_MEDIA_ASSET: 'Upload Media Asset',
  USER_ACCESS: 'User Access',
  USER_EMAIL_EXISTS: 'This email is already used for a user.',
  USERS: 'Users',
  VIEW_ALL_TRANSACTIONS: 'View All Transactions',
  VIEW_LESS: 'View Less',
  WELCOME: 'Welcome',
  WELCOME_USER: 'Welcome, {0}',
  WELCOME_MESSAGE_TITLE: 'You’ve joined Accelerator Funder Portal!',
  WELCOME_MESSAGE_BODY: 'You can now view your account transactions and portfolio status.',
  WOMEN: 'Women',
  YES: 'Yes',
  YOUR_CONTRIBUTION: 'Your Contribution',
  YOUR_PORTFOLIO: 'Your Portfolio',
  ZERO_HUNGER: 'Zero Hunger',
};

export type Strings = typeof strings;
export type StringsMap = GlobalStrings<Strings>;

const stringsMap = new LocalizedStrings({
  en: strings,
} as unknown as StringsMap);

export default stringsMap;
