import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import BusyPageSpinner from '@common-components/BusyPageSpinner';
import { User } from '@common-type/User';
import useEnvironment from '@common-utils/useEnvironment';
import { AppTypeContext } from '@common-src/providers/contexts';
import { APP_PATHS } from '@common-src/constants';
import { useUser } from '@common-src/providers';

export type RequireUserProps = {
  app: 'funder' | 'admin';
};

const RequireUser = ({ app }: RequireUserProps): JSX.Element => {
  const [user, setUser] = useState<User | undefined>();
  const currentUser = useUser();
  const env = useEnvironment();
  const guardUserRoute = env.isStaging || env.isProduction;

  useEffect(() => {
    setUser(currentUser?.user);
  }, [currentUser]);

  if (!user?.userType) {
    return <BusyPageSpinner />;
  }

  if (guardUserRoute) {
    if (app === 'funder' && user.userType !== 'Funder') {
      window.location.href = `${window.location.origin}${APP_PATHS.ADMIN}`;
      return <BusyPageSpinner />;
    }

    if (app === 'admin' && user.userType === 'Funder') {
      window.location.href = `${window.location.origin}${APP_PATHS.FUNDER}`;
      return <BusyPageSpinner />;
    }
  }

  // returns child route elements
  return (
    <AppTypeContext.Provider value={{ isFunder: app === 'funder' }}>
      <Outlet />
    </AppTypeContext.Provider>
  );
};

export default RequireUser;
