import { objectSlice } from '@common-features/slicer';
import { requestGetCountries } from './countriesThunks';
import { Country } from '@common-type/Country';

const getCountriesSlice = objectSlice<Country[]>({
  name: 'getCountries',
  thunk: requestGetCountries,
  keyFn: () => 'countries', // use a hardcoded key
});

const reducers = {
  getCountries: getCountriesSlice.reducer,
};

export default reducers;
