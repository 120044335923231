/**
 * Define policies for keys
 */
export type KeyPolicy = {
  maxKeyCount?: number;
};

/**
 * Entry point to cleaning up keys
 */
export const cleanupKeys = (state: Record<string, any>, policy: KeyPolicy) => {
  if (policy.maxKeyCount) {
    cleanupMaxKeys(state, policy.maxKeyCount);
  }
};

/**
 * Clean up by maxcount
 */
export const cleanupMaxKeys = (state: Record<string, any>, maxKeyCount: number) => {
  const keys = Object.keys(state);

  if (maxKeyCount >= keys.length) {
    return;
  }

  const keysToDelete = keys
    .sort((key1: string, key2: string) => (state[key1].updatedAt ?? 0) - (state[key2].updatedAt ?? 0))
    .slice(0, keys.length - maxKeyCount);

  keysToDelete.forEach((key: any) => delete state[key]);
};

/**
 * Clear all keys
 */
export const clearAllKeys = (state: Record<string, any>) => {
  Object.keys(state).forEach((key: string) => delete state[key]);
};
