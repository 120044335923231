import React, { useEffect, useState } from 'react';
import { Box, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from '@redux/store';
import { UserProvider } from '@common-src/providers';
import BusyPageSpinner from '@common-components/BusyPageSpinner';
import RequireUser from '@common-components/RequireUser';
import ErrorBoundary from '@common-src/ErrorBoundary';
import Login from '@common-scenes/Login';
import theme from '@common-src/theme';
// needed to import TW palette color types
/* eslint-disable @typescript-eslint/no-unused-vars */
import TerrawareThemeOptions from '@terraware/web-components/style-dictionary-dist/TerrawareTheme';
import { useDeviceInfo } from '@terraware/web-components/utils';

const FunderApp = React.lazy(() => import('./routes'));

interface StyleProps {
  useBackgroundImage?: boolean;
}

const useStyles = makeStyles((styleTheme: Theme) => ({
  container: {
    background: (props: StyleProps) =>
      props.useBackgroundImage
        ? `linear-gradient(rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.9) 75%, transparent 100%), url('/background.jpg') transparent no-repeat center center / cover`
        : `linear-gradient(180deg, ${styleTheme.palette.TwClrBaseGray025} 0%, ${styleTheme.palette.TwClrBgBrandTertiary} 100%)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    overflow: 'hidden',
    width: '100vw',
    height: '100dvh',
  },
}));

const AppContent = (): JSX.Element => {
  const location = useLocation();
  const [useBackgroundImage, setUseBackgroundImage] = useState<boolean>(false);
  const classes = useStyles({ useBackgroundImage });
  const { isDesktop } = useDeviceInfo();

  useEffect(() => {
    if (location?.pathname?.match(/\/overview/) && isDesktop) {
      if (!useBackgroundImage) {
        setUseBackgroundImage(true);
      }
    } else if (useBackgroundImage) {
      setUseBackgroundImage(false);
    }
  }, [isDesktop, location, useBackgroundImage]);

  return (
    <Box className={classes.container}>
      <Routes>
        <Route path={'*'} element={<RequireUser app='funder' />}>
          <Route
            path={'*'}
            element={
              <React.Suspense fallback={<BusyPageSpinner />}>
                <FunderApp />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </Box>
  );
};

const App = (): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <ErrorBoundary>
            <Router>
              <Routes>
                <Route path='/login' element={<Login />} />
                <Route
                  path='*'
                  element={
                    <UserProvider>
                      <AppContent />
                    </UserProvider>
                  }
                />
              </Routes>
            </Router>
          </ErrorBoundary>
        </Provider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default App;
