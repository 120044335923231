import { createAsyncThunk } from '@reduxjs/toolkit';
import { Response } from '@common-services/http';
import { Cohort } from '@type/Cohort';
import { CohortsService } from '@services/cohort';
import strings from '@common-src/strings';

export const requestGetCohortList = createAsyncThunk('getCohortList', async (_, { rejectWithValue }) => {
  const response: Response = await CohortsService.getCohorts();
  if (response.requestSucceeded) {
    return response.data.cohorts as Cohort[];
  }

  return rejectWithValue(response.error || strings.GENERIC_ERROR);
});

export const requestGetCohort = createAsyncThunk('getCohort', async (id: number, { rejectWithValue }) => {
  const response: Response = await CohortsService.getCohort(id);
  if (response.requestSucceeded) {
    return response.data.cohort as Cohort;
  }

  return rejectWithValue(response.error || strings.GENERIC_ERROR);
});
