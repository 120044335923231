import { Action, combineReducers } from '@reduxjs/toolkit';
import { reducers, defaultActions } from '@common-redux/rootReducer';
import fundingEntitySlice from '@features/fundingEntity/fundingEntitySlice';
import fundingEntityUsersSlice from '@features/fundingEntityUsers/fundingEntityUsersSlice';
import cohortSlice from '@features/cohorts/cohortsSlice';
import participantSlice from '@features/participants/participantsSlice';
import participantFilesSlice from '@features/participantFiles/ParticipantFileSlice';
import transactionsSlice from '@features/transactions/transactionsSlice';

const { getFundingEntity, updateFundingEntity } = fundingEntitySlice;
const { getFundingEntityUsers } = fundingEntityUsersSlice;

const { getCohortList, getCohort } = cohortSlice;

const { getParticipantList, getParticipant } = participantSlice;
const { getParticipantFilesList } = participantFilesSlice;

const { getTransactionList, getTransactionsByCohortList } = transactionsSlice;

const funderReducers = {
  // This is where non-admin related funder reducers will go
  getFundingEntity,
  getFundingEntityUsers,
  updateFundingEntity,
  getCohortList,
  getCohort,
  getParticipantList,
  getParticipant,
  getParticipantFilesList,
  getTransactionList,
  getTransactionsByCohortList,
};

const combinedReducers = combineReducers({ ...reducers, ...funderReducers });

// used for building the typed root state
type CombinedState = ReturnType<typeof combinedReducers>;

export const rootReducer = (state: CombinedState | undefined, action: Action) => {
  defaultActions(state, action);

  return combinedReducers(state as any, action);
};

export type RootState = ReturnType<typeof rootReducer>;
