import { createSlice } from '@reduxjs/toolkit';
import { requestLogout } from './authThunks';

export type AuthState = {
  loggedOut: boolean;
  loggingOut: boolean;
  error: string;
};

const initialState: AuthState = {
  loggedOut: false,
  loggingOut: false,
  error: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(requestLogout.pending, (state) => {
        state.loggedOut = false;
        state.loggingOut = true;
      })
      .addCase(requestLogout.fulfilled, (state) => {
        state.loggedOut = true;
        state.loggingOut = false;
        state.error = '';
      })
      .addCase(requestLogout.rejected, (state, action) => {
        state.loggedOut = false;
        state.loggingOut = false;
        state.error = action.payload as string;
      }),
});

export const authReducer = authSlice.reducer;
