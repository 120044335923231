import { createSlice, AsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { SearchResponse, DeletionStore, ObjectStore, SearchStore, BulkStore, BulkStatus } from './state';
import { cleanupKeys, KeyPolicy } from './key';

/**
 * Generic slicers to reduce boilerplate code
 */

type Id = { id?: number };

const DEFAULT_KEY_POLICY = {
  maxKeyCount: 2,
};

const updateState = (state: Record<string, any>, key: string, data: Record<any, any>, keyPolicy: KeyPolicy) => {
  state[key] = {
    ...data,
    updatedAt: Date.now(),
  };
  cleanupKeys(state, keyPolicy);
};

/**
 * Slicer for deletion thunks
 */

export type ThunkArgs = {
  arg: any;
  requestId: string;
};

export type ThunkKeyFn = (args: ThunkArgs) => string;

export type DeleteSliceParams = {
  name: string;
  thunk: AsyncThunk<number, any, any>;
  keyFn: ThunkKeyFn;
  reducers?: any;
  keyPolicy?: KeyPolicy;
};

const getKey = (action: any, key: ThunkKeyFn): string => {
  const { arg, requestId } = action.meta as any;
  return key({ arg, requestId });
};

export const deleteSlice = ({ name, thunk, keyFn, reducers, keyPolicy = DEFAULT_KEY_POLICY }: DeleteSliceParams) =>
  createSlice({
    name,
    initialState: {} as DeletionStore,
    reducers: {
      ...(reducers || {}),
    },
    extraReducers: (builder) =>
      builder
        .addCase(thunk.pending, (state, action) => {
          updateState(
            state,
            getKey(action, keyFn),
            {
              id: undefined,
              status: 'pending',
              error: '',
            },
            keyPolicy
          );
        })
        .addCase(thunk.fulfilled, (state, action: PayloadAction<number>) => {
          updateState(
            state,
            getKey(action, keyFn),
            {
              id: action.payload,
              status: 'success',
              error: '',
            },
            keyPolicy
          );
        })
        .addCase(thunk.rejected, (state, action) => {
          updateState(
            state,
            getKey(action, keyFn),
            {
              id: undefined,
              status: 'error',
              error: action.payload as string,
            },
            keyPolicy
          );
        }),
  });

/**
 * Slicer for identifiable single object thunks
 */

export type ObjectSliceParams<T> = {
  name: string;
  thunk: AsyncThunk<T, any, any>;
  keyFn: ThunkKeyFn;
  reducers?: any;
  keyPolicy?: KeyPolicy;
};

export const objectWithIdSlice = <T extends Id>({
  name,
  thunk,
  keyFn,
  reducers,
  keyPolicy = DEFAULT_KEY_POLICY,
}: ObjectSliceParams<T>) =>
  createSlice({
    name,
    initialState: {} as ObjectStore<T>,
    reducers: {
      ...(reducers || {}),
    },
    extraReducers: (builder) =>
      builder
        .addCase(thunk.pending, (state, action) => {
          updateState(
            state,
            getKey(action, keyFn),
            {
              id: undefined,
              data: undefined,
              status: 'pending',
              error: '',
            },
            keyPolicy
          );
        })
        .addCase(thunk.fulfilled, (state, action: PayloadAction<T>) => {
          updateState(
            state,
            getKey(action, keyFn),
            {
              id: action.payload.id,
              data: action.payload as any,
              status: 'success',
              error: '',
            },
            keyPolicy
          );
        })
        .addCase(thunk.rejected, (state, action) => {
          updateState(
            state,
            getKey(action, keyFn),
            {
              id: undefined,
              data: undefined,
              status: 'error',
              error: action.payload as string,
            },
            keyPolicy
          );
        }),
  });

export const objectSlice = <T>({
  name,
  thunk,
  keyFn,
  reducers,
  keyPolicy = DEFAULT_KEY_POLICY,
}: ObjectSliceParams<T>) =>
  createSlice({
    name,
    initialState: {} as ObjectStore<T>,
    reducers: {
      ...(reducers || {}),
    },
    extraReducers: (builder) =>
      builder
        .addCase(thunk.pending, (state, action) => {
          updateState(
            state,
            getKey(action, keyFn),
            {
              data: undefined,
              status: 'pending',
              error: '',
            },
            keyPolicy
          );
        })
        .addCase(thunk.fulfilled, (state, action: PayloadAction<T>) => {
          updateState(
            state,
            getKey(action, keyFn),
            {
              data: action.payload as any,
              status: 'success',
              error: '',
            },
            keyPolicy
          );
        })
        .addCase(thunk.rejected, (state, action) => {
          updateState(
            state,
            getKey(action, keyFn),
            {
              data: undefined,
              status: 'error',
              error: action.payload as string,
            },
            keyPolicy
          );
        }),
  });

/**
 * Slice for bulk operation semantics
 */
export const bulkSlice = <T>({ name, thunk, keyFn, reducers, keyPolicy = DEFAULT_KEY_POLICY }: ObjectSliceParams<T>) =>
  createSlice({
    name,
    initialState: {} as BulkStore<T>,
    reducers: {
      ...(reducers || {}),
    },
    extraReducers: (builder) =>
      builder
        .addCase(thunk.pending, (state, action) => {
          updateState(
            state,
            getKey(action, keyFn),
            {
              data: undefined,
              status: 'pending',
              error: '',
            },
            keyPolicy
          );
        })
        .addCase(thunk.fulfilled, (state, action: PayloadAction<T>) => {
          const bulkStatus: BulkStatus<T> = action?.payload as BulkStatus<T>;
          const failed = bulkStatus?.failed;
          updateState(
            state,
            getKey(action, keyFn),
            {
              data: action.payload as any,
              status: failed?.length ? 'partial-success' : 'success',
              error: failed?.map((f) => f.error)?.join(', ') ?? '',
            },
            keyPolicy
          );
        })
        .addCase(thunk.rejected, (state, action) => {
          updateState(
            state,
            getKey(action, keyFn),
            {
              data: undefined,
              status: 'error',
              error: action.payload as string,
            },
            keyPolicy
          );
        }),
  });

/**
 * Slicer for search thunks
 */

export type SearchSliceParams = {
  name: string;
  thunk: AsyncThunk<any, any, any>;
  keyFn: ThunkKeyFn;
  reducers?: any;
  keyPolicy?: KeyPolicy;
};

export const searchSlice = <T extends Id>({
  name,
  thunk,
  keyFn,
  reducers,
  keyPolicy = DEFAULT_KEY_POLICY,
}: SearchSliceParams) =>
  createSlice({
    name,
    initialState: {} as SearchStore<T>,
    reducers: {
      ...(reducers || {}),
    },
    extraReducers: (builder) =>
      builder
        .addCase(thunk.pending, (state, action) => {
          updateState(
            state,
            getKey(action, keyFn),
            {
              query: undefined,
              data: [],
              status: 'pending',
              error: '',
            },
            keyPolicy
          );
        })
        .addCase(thunk.fulfilled, (state, action: PayloadAction<SearchResponse<T>>) => {
          updateState(
            state,
            getKey(action, keyFn),
            {
              query: action.payload.query,
              data: action.payload.data as any,
              status: 'success',
              error: '',
            },
            keyPolicy
          );
        })
        .addCase(thunk.rejected, (state, action) => {
          updateState(
            state,
            getKey(action, keyFn),
            {
              query: undefined,
              data: [],
              status: 'error',
              error: action.payload as string,
            },
            keyPolicy
          );
        }),
  });

/**
 * Utility to create CRUD slices
 */

export type CrudThunkParams = {
  keyFn: ThunkKeyFn;
  thunk: AsyncThunk<any, any, any>;
};

export type CrudSliceParams = {
  createThunk?: CrudThunkParams;
  getThunk?: CrudThunkParams;
  updateThunk?: CrudThunkParams;
  deleteThunk?: CrudThunkParams;
  searchThunk?: CrudThunkParams;
  resourceName: string;
};

export const crudSlice = <T extends Id>({
  createThunk,
  getThunk,
  updateThunk,
  deleteThunk,
  searchThunk,
  resourceName,
}: CrudSliceParams) => {
  const thunks = [];

  const getThunkFrom = (prefix: string, params?: CrudThunkParams) => {
    if (!params) {
      return;
    }
    const { thunk, keyFn } = params;
    const name = `${prefix}${resourceName}`;
    const createdThunk = objectWithIdSlice<T>({ name, keyFn, thunk });
    thunks.push({ name, thunk: createdThunk });
  };

  getThunkFrom('create', createThunk);
  getThunkFrom('get', getThunk);
  getThunkFrom('update', updateThunk);

  if (deleteThunk) {
    const { thunk, keyFn } = deleteThunk;
    const name = `delete${resourceName}`;
    const createdThunk = deleteSlice({ name, keyFn, thunk });
    thunks.push({ name, thunk: createdThunk });
  }

  if (searchThunk) {
    const { thunk, keyFn } = searchThunk;
    const name = `search${resourceName}`;
    const createdThunk = searchSlice({ name, keyFn, thunk });
    thunks.push({ name, thunk: createdThunk });
  }

  return thunks;
};

/**
 * Utility to create CRUD reducers
 */

export const crudReducers = <T extends Id>(params: CrudSliceParams) => {
  const thunks = crudSlice<T>(params);
  return thunks.reduce((acc, { name, thunk }) => {
    acc[name] = thunk.reducer;
    return acc;
  }, {} as any);
};
