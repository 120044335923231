import { objectSlice } from '@common-features/slicer';
import { Participant } from '@type/Participant';
import { requestGetParticipant, requestGetParticipantList } from '@features/participants/participantsThunks';

const getParticipantList = objectSlice<Participant[]>({
  name: 'getParticipantList',
  thunk: requestGetParticipantList,
  keyFn: ({ arg }) => arg, // key by cohortId
  keyPolicy: {},
});

const getParticipant = objectSlice<Participant>({
  name: 'getParticipant',
  thunk: requestGetParticipant,
  keyFn: ({ arg }) => arg, // key by participantId
});

const reducers = {
  getParticipantList: getParticipantList.reducer,
  getParticipant: getParticipant.reducer,
};

export default reducers;
