import { User } from '@common-type/User';
import { objectSlice } from '@common-features/slicer';
import { requestGetCurrentUser, requestUpdateCurrentUser } from '@common-features/currentUser/currentUserThunks';

export const getCurrentUserSlice = objectSlice<User>({
  name: 'getCurrentUser',
  thunk: requestGetCurrentUser,
  keyFn: () => 'get', // use a hardcoded key
});

export const updateCurrentUserSlice = objectSlice({
  name: 'updateCurrentUser',
  thunk: requestUpdateCurrentUser,
  keyFn: () => 'update', // use a hardcoded key
});

export const getCurrentUserReducer = getCurrentUserSlice.reducer;
export const updateCurrentUserReducer = updateCurrentUserSlice.reducer;
