import { createAsyncThunk } from '@reduxjs/toolkit';
import { Response } from '@common-services/http';
import { FundingEntityService } from '@services/fundingEntity';
import strings from '@common-src/strings';
import { FundingEntityUser } from '@type/FundingEntityUser';

export const requestGetFundingEntityUsers = createAsyncThunk(
  'getFundingEntityUsers',
  async (_, { rejectWithValue }) => {
    const response: Response = await FundingEntityService.getFundingEntityUsers();
    if (response.requestSucceeded) {
      return response.data.users as FundingEntityUser[];
    }

    return rejectWithValue(response.error || strings.GENERIC_ERROR);
  }
);
