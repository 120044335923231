import { FundingEntity } from '@type/FundingEntity';
import { objectSlice } from '@common-features/slicer';
import { requestGetFundingEntity, requestUpdateFundingEntity } from './fundingEntityThunks';

const getFundingEntity = objectSlice<FundingEntity>({
  name: 'getFundingEntity',
  thunk: requestGetFundingEntity,
  keyFn: ({ arg }) => arg, // use the funding entity id as the key
}).reducer;

const updateFundingEntity = objectSlice<FundingEntity>({
  name: 'updateFundingEntity',
  thunk: requestUpdateFundingEntity,
  keyFn: ({ arg }) => arg.id, // use the funding entity id as the key
}).reducer;

const reducers = {
  getFundingEntity,
  updateFundingEntity,
};

export default reducers;
