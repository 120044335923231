import { Response } from '@common-services/http';

const logout = async (): Promise<Response> => {
  window.location.href = '/api/logout';

  return Promise.resolve({ requestSucceeded: true });
};

export const AuthService = {
  logout,
};
