import { HttpService, Response } from '@common-services/http';

const COUNTRIES = '/api/v1/values/countries';

const httpCountries = HttpService.root(COUNTRIES);

const listCountries = async (): Promise<Response> => {
  return await httpCountries.get({});
};

export const CountriesService = {
  listCountries,
};
