import { HttpService, Response } from '@common-services/http';

const COHORTS = '/api/v1/cohorts';
const COHORT = '/api/v1/cohorts/{cohortId}';

const httpCohorts = HttpService.root(COHORTS);
const httpCohort = HttpService.root(COHORT);

const getCohorts = async (): Promise<Response> => await httpCohorts.get({});

const getCohort = async (id: number): Promise<Response> =>
  await httpCohort.get({
    urlReplacements: { '{cohortId}': id.toString() },
  });

export const CohortsService = {
  getCohorts,
  getCohort,
};
