import { objectSlice } from '@common-features/slicer';
import { ParticipantFile } from '@type/ParticipantFile';
import { requestGetParticipantFilesList } from '@features/participantFiles/ParticipantFileThunks';

const getParticipantFilesList = objectSlice<ParticipantFile[]>({
  name: 'getParticipantFilesList',
  thunk: requestGetParticipantFilesList,
  keyFn: ({ arg }) => arg,
});

const reducers = {
  getParticipantFilesList: getParticipantFilesList.reducer,
};

export default reducers;
