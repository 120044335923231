import { HttpService, Response } from '@common-services/http';

const FILES = '/api/v1/files';

const httpFiles = HttpService.root(FILES);

const getFiles = async (participantId: string): Promise<Response> =>
  await httpFiles.get({
    params: { participantId },
  });

export const FilesService = {
  getFiles,
};
