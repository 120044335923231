import { createAsyncThunk } from '@reduxjs/toolkit';
import { Response } from '@common-services/http';
import strings from '@common-src/strings';
import { UserPreferencesService } from '@common-services/userPreferences';
import { UserPreferences } from '@common-type/UserPreferences';

export const requestGetUserPreferences = createAsyncThunk('getUserPreferences', async (_, { rejectWithValue }) => {
  const response: Response = await UserPreferencesService.getUserPreferences();
  if (response.requestSucceeded) {
    return response.data?.value as UserPreferences;
  }

  return rejectWithValue(response.error || strings.GENERIC_ERROR);
});

export const requestUpdateUserPreferences = createAsyncThunk(
  'updateUserPreferences',
  async (toUpdate: UserPreferences, { rejectWithValue }) => {
    const response: Response = await UserPreferencesService.getUserPreferences();

    if (response.requestSucceeded) {
      const preferences = { ...response.data?.value, ...toUpdate };
      const updateResponse: Response = await UserPreferencesService.setUserPreferences(preferences);

      if (updateResponse.requestSucceeded) {
        return updateResponse.data?.value as UserPreferences;
      } else {
        return rejectWithValue(response.error || strings.GENERIC_ERROR);
      }
    } else {
      return rejectWithValue(response.error || strings.GENERIC_ERROR);
    }
  }
);
