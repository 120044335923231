import { Action, combineReducers } from '@reduxjs/toolkit';
import { getCurrentUserReducer, updateCurrentUserReducer } from './features/currentUser/currentUserSlice';
import { authReducer } from './features/auth/authSlice';
import { LOGOUT_ACTION_PREFIX } from './features/auth/authThunks';
import { messageReducer } from './features/message/messageSlice';
import { snackbarReducer } from './features/snackbar/snackbarSlice';
import userPreferencesReducer from '@common-features/userPreferences/userPreferencesSlice';
import CountriesReducers from '@common-features/countries/countriesSlice';

// deconstruct funder reducers

const { getUserPreferences, updateUserPreferences } = userPreferencesReducer;
const { getCountries } = CountriesReducers;

// assembly of app reducers
export const reducers = {
  getCurrentUser: getCurrentUserReducer,
  updateCurrentUser: updateCurrentUserReducer,
  auth: authReducer,
  message: messageReducer,
  snackbar: snackbarReducer,

  // user preferences
  getUserPreferences,
  updateUserPreferences,

  // countries
  getCountries,
};

const combinedReducers = combineReducers(reducers);

// used for building the typed root state
type CombinedState = ReturnType<typeof combinedReducers>;

export const defaultActions = (state: CombinedState | undefined, action: Action) => {
  if (action.type === 'RESET_APP' || action.type === `${LOGOUT_ACTION_PREFIX}/fulfilled`) {
    state = undefined;
  }
};

export const rootReducer = (state: CombinedState | undefined, action: Action) => {
  defaultActions(state, action);

  return combinedReducers(state as any, action);
};

export type RootState = ReturnType<typeof rootReducer>;
